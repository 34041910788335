import { gql, useQuery } from '@apollo/client';

// Define the GraphQL query
export const GET_ALL_ACTIVATION_KEYS = gql`
  query GetAllActivationKeys {
    getAllActivationKeys {
      licenseId
      activationKey
      customerName
      fingerprint
      expiryDate
      license {
        licenseId
      }
    }
  }
`;

