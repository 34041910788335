import { gql, useMutation } from '@apollo/client';

// Define the GraphQL mutation
 export const CREATE_ACTIVATION_KEY = gql`
  mutation CreateActivationKey($data: ActivationKeyInput!) {
    createActivationKey(data: $data) {
      licenseId
      activationKey
      customerName
      fingerprint
      expiryDate
    }
  }
`;