import { useMutation } from "@apollo/client";
import { CloudFramePrimaryButton } from "@cloudframe/button";
import { CloudFrameCheckbox } from "@cloudframe/checkbox";
import { CloudFrameDatepicker } from "@cloudframe/datepicker";
import { CloudFrameDropdown } from "@cloudframe/dropdown";
import { CloudFrameTextBox } from "@cloudframe/textbox";
import {
  DayOfWeek,
  IDropdownProps,
  ITextFieldProps,
  defaultDatePickerStrings,
} from "@fluentui/react";
import { FormEvent, ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../@styles/css/form-element.css";
import { IForm } from "../../@types/form.types";
import {
  ENVIRONMENT,
  LICENSE_STATUS,
  LICENSE_TYPE,
  PRODUCT,
} from "../../constants/license.constants";
import {
  MUTATION_CREATE_LICENSE,
  MUTATION_EDIT_LICENSE,
} from "../../graphql-mutations/license.mutation";
import { getStartingOfDay, getTomorrow } from "../../utils/date.utils";
import { parseInt } from "../../utils/numeric.utils";
import { isBlank } from "../../utils/string.utils";
import CustomLabel from "../custom-label/custom-label";
import { useStyles } from "./license-form.styles";

const LicenseForm = (props: IForm) => {
  const { data } = props;
  const classes = useStyles();
  const [formValues, setFormValues] = useState<any>({
    isOfflineLicense: false,
  });
  const [errors, setErrors] = useState<any>({});
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [createLicense] = useMutation(MUTATION_CREATE_LICENSE);
  const [editLicense] = useMutation(MUTATION_EDIT_LICENSE);
  const [formSubmitAttempted, setFormSubmitAttempted] = useState(false);
  const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  const jiraTicketPattern = /^https:\/\/cloudframe\.atlassian\.net\/.+$/;

  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      checkLicenseExpiration(data);
    }
    if (data?.id) {
      setFormValues({
        ...data,
        linesOfCodeCategory:
          data.linesOfCodeCategory === 10000 ? "HIGH" : "LOW",
      });
    } else {
      setFormValues({
        licenseType: "",
        product: "",
        linesOfCode: 0,
        SmartDocLOC: 0,
        platformEffectiveDate: new Date(),
        platformExpiryDate: getTomorrow(new Date()),
        consultingHours: 1,
        enableCDN: "true",
        isOfflineLicense: true,
        linesOfCodeCategory: "LOW",
        supportLevel: "PREMIUM",
        productTrainingProvided: true,
        environment: "",
        serviceDeskID:"",
      });
    }
  }, [data]);

  const validateForm = () => {
    const newErrors: any = {};
    if (isBlank(formValues.licenseType)) {
      newErrors.licenseType = "Please select an option";
    }
    if (isBlank(formValues.product)) {
      newErrors.product = "Please select an option";
    }
    if (isBlank(formValues.environment)) {
      newErrors.environment = "Please select an option";
    }
    if (isBlank(formValues.linesOfCodeCategory)) {
      newErrors.linesOfCodeCategory = "Please select an option";
    }
    if (isBlank(formValues.platformEffectiveDate)) {
      newErrors.platformEffectiveDate = "Please select a date";
    } else {
      const sod = getStartingOfDay(formValues.platformEffectiveDate);
      if (sod < getStartingOfDay(new Date())) {
        newErrors.platformEffectiveDate = "Effective date must be today or onward";
      }
    }
    if (isBlank(formValues.platformExpiryDate)) {
      newErrors.platformExpiryDate = "Please select a date";
    } else {
      const sod = getStartingOfDay(formValues.platformExpiryDate);
      if (sod <= formValues.platformEffectiveDate) {
        newErrors.platformExpiryDate =
          "Expiry date must be greater than effective date";
      }
    }
    if(isBlank(formValues.serviceDeskID)){
      newErrors.serviceDeskID = "Please enter the value"
    }
    if (isBlank(formValues.supportLevel)) {
      newErrors.supportLevel = "Please select an option";
    }
    if (isBlank(formValues.productTrainingProvided)) {
      newErrors.productTrainingProvided = "Please select an option";
    }
    console.log(formValues.consultingHours);
    if (isBlank(formValues.consultingHours)) {
      newErrors.consultingHours = "Please provide a valid number";
    }
    if (!formValues.email || !emailPattern.test(formValues.email)) {
      newErrors.email = "Pleae enter valid email address";
    }
    if (
      !formValues.jiraTicket ||
      !jiraTicketPattern.test(formValues.jiraTicket)
    ) {
      newErrors.jiraTicket = "Pleae enter valid Jira ticket URL";
    }

    if (formValues.licenseType == LICENSE_TYPE.Training) {
      if (isBlank(formValues.allowedProgram)) {
        newErrors.allowedProgram = "Please provide allowed program hash names";
      }
      if (isBlank(formValues.allowedJcl)) {
        newErrors.allowedJcl = "Please provide allowed JCL hash names";
      }
    }

    setErrors({ ...newErrors });
    return !Object.keys(newErrors).length;
  };

  const updateLicenseDetails = async () => {
    const {
      product,
      customerName,
      email,
      __typename,
      isOfflineLicense,
      ...licenseDataToUpdate
    } = formValues;

    const result = await editLicense({
      variables: {
        data: licenseDataToUpdate,
      },
    });
    if (result.data.editLicense) {
      navigate("/all-licenses");
    }
  };

  const onFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setFormSubmitting(true);
    setFormSubmitAttempted(true);
     const isFormValid = validateForm();
   // const isFormValid = true;
    try {
      if (isFormValid && data) {
        await updateLicenseDetails();
      } else if (isFormValid) {
        const loc =
          formValues.product === "Relocate"
            ? Number.MAX_SAFE_INTEGER
            : formValues.linesOfCode;
        const formData = {
          ...formValues,
          enableCDN: formValues.enableCDN === "true",
          linesOfCode: loc,
          platformEffectiveDate: formValues.platformEffectiveDate?.toISOString(),
          platformExpiryDate: formValues.platformExpiryDate?.toISOString(),
        };
        console.log('Creating the license with the form data ', formData);
        const result = await createLicense({ variables: { data: formData } });
        console.log("License created");
        if (result.data.createLicense) {
          navigate("/all-licenses");
        }
      }
    } catch (error) {
      console.log(error);
    }
    setFormSubmitting(false);
  };

  const onRenderLabel = (
    props: ITextFieldProps | IDropdownProps | undefined
  ): ReactElement =>
    props ? (
      <CustomLabel id={props.label?.replaceAll(" ", "")} {...props} />
    ) : (
      <div />
    );

  const onLocCategoryNav = () => {
    // eslint-disable-next-line no-restricted-globals
    open(
      "https://internal.cloudframe.com/wp/2021/10/26/generating-licenses-for-customers/",
      "_blank",
      ""
    );
  };

  const getLocCategoryHint = (): ReactElement => {
    return (
      <span>
        Categories are five-digit numbers with each digit correlating to
        different usages. Currently, CloudFrame had allocated only the first
        digit and intends to use the remaining four digits in the future for
        different usage. The below section of the article explains the usage of
        the first digit of the category which is correlating to copybooks. For
        more details, please follow
        <span
          role="link"
          onKeyDown={onLocCategoryNav}
          onClick={onLocCategoryNav}
          style={{ color: "blue", cursor: "pointer" }}
          tabIndex={0}
        >
          {" these instructions."}
        </span>
      </span>
    );
  };

  const isLicenseExpired = (licenseInfo: any): boolean => {
    const effectiveDate = new Date(licenseInfo.platformEffectiveDate);
    const expiryDate = new Date(licenseInfo.platformExpiryDate);
    const currentDate = new Date();
    return effectiveDate <= currentDate && expiryDate < currentDate;
  };

  const checkLicenseExpiration = (licenseInfo: any): void => {
    if (isLicenseExpired(licenseInfo)) {
      licenseInfo.status = LICENSE_STATUS.EXPIRED;
    }
  };

  const onChangeLicenseType = (e: any, item: any) => {
    if ([LICENSE_TYPE.Training].includes(item.key)) {
      setFormValues({
        ...formValues,
        licenseType: item.key,
        linesOfCode: Number.MAX_SAFE_INTEGER,
        consultingHours: 0,
      });
    } else {
      setFormValues({
        ...formValues,
        licenseType: item.key,
      });
    }
    setErrors({ ...errors, licenseType: undefined });
  };

  const onChangeEnvironmentType = (event, option) => {
    setFormValues({
      ...formValues,
      environment: option.key
    });
  }

  return (
    <div className={classes.root}>
      <div
        id="form"
        className={classes.formBody}
        style={{ borderRadius: "25px" }}
      >
        <div className={classes.subContainer}>
          <h3>{data ? "Renew License" : "Create new license"}</h3>
          <form onSubmit={onFormSubmit}>
            <CloudFrameDropdown
              placeholder="Select an option"
              label="License Type"
              className={classes.mb8}
              errorMessage={errors.licenseType}
              required
              defaultSelectedKey={[formValues.licenseType]}
              onChange={onChangeLicenseType}
              options={[
                { key: LICENSE_TYPE.POT, text: LICENSE_TYPE.POT },
                { key: LICENSE_TYPE.POC, text: LICENSE_TYPE.POC },
                { key: LICENSE_TYPE.Customer, text: LICENSE_TYPE.Customer },
                { key: LICENSE_TYPE.Training, text: LICENSE_TYPE.Training },
                { key: LICENSE_TYPE.Pilot, text: LICENSE_TYPE.Pilot },
                { key: LICENSE_TYPE.Partner, text: LICENSE_TYPE.Partner },
                { key: LICENSE_TYPE.Internal, text: LICENSE_TYPE.Internal },
                { key: LICENSE_TYPE.POC_ONPREM, text: LICENSE_TYPE.POC_ONPREM}
              ]}
            />
            <CloudFrameTextBox
              onChange={(_, value) => {
                setFormValues({ ...formValues, customerName: value });
              }}
              required
              label="Customer name"
              className={classes.mb8}
              value={formValues.customerName}
              disabled={!!data}
            />
            <CloudFrameTextBox
              label="Email"
              className={classes.mb8}
              value={formValues.email}
              required
              disabled={!!data}
              errorMessage={errors.email}
              onChange={(_, value) => {
                setFormValues({ ...formValues, email: value });
                if (!value || !emailPattern.test(value)) {
                  setErrors({
                    ...errors,
                    email: "Please enter valid email address",
                  });
                } else {
                  setErrors({ ...errors, email: undefined });
                }
              }}
            />
            {[LICENSE_TYPE.Training].includes(
              formValues.licenseType
            ) && (
                <CloudFrameTextBox
                  label="Allowed programs (Program hashes)"
                  ariaLabel="Please enter comma separated program hashes here. Program hashes are calculated from program file content. The license will only allow the given programs to migrate."
                  className={classes.mb8}
                  value={formValues.allowedProgram}
                  errorMessage={errors.allowedProgram}
                  onRenderLabel={onRenderLabel}
                  onChange={(_, value) => {
                    setFormValues({ ...formValues, allowedProgram: value });
                    if (!value) {
                      setErrors({
                        ...errors,
                        allowedProgram: "Please enter program hashes",
                      });
                    } else {
                      setErrors({ ...errors, allowedProgram: undefined });
                    }
                  }}
                  required
                />
              )}
            {formValues.licenseType == LICENSE_TYPE.Training && (
              <CloudFrameTextBox
                label="Allowed JCLS (JCL hashes)"
                ariaLabel="Please enter comma separated JCL hashes here. Program hashes are calculated from JCL file content. The license will only allow the given JCLs to migrate."
                className={classes.mb8}
                value={formValues.allowedJcl}
                errorMessage={errors.allowedJcl}
                onRenderLabel={onRenderLabel}
                onChange={(_, value) => {
                  setFormValues({ ...formValues, allowedJcl: value });
                  if (!value) {
                    setErrors({
                      ...errors,
                      allowedJcl: "Please enter JCL hashes",
                    });
                  } else {
                    setErrors({ ...errors, allowedJcl: undefined });
                  }
                }}
              />
            )}
            <CloudFrameDropdown
              placeholder="Select an option"
              label="Product"
              required
              errorMessage={errors.product}
              defaultSelectedKey={formValues.product}
              onChange={(e, item: any) => {
                const isRelocate = formValues.product === "Relocate";
                const isBaseRuntime = formValues.product === "Base Runtime (DCIO - Base)"
                if (isRelocate) {
                  setFormValues({
                    ...formValues,
                    product: item.key,
                    linesOfCode: Number.MAX_SAFE_INTEGER,
                  });
                  setErrors({ ...errors, product: undefined });
                } else if (isBaseRuntime) {
                  setFormValues({ ...formValues, product: PRODUCT.Base_Runtime })
                }
                else {
                  setFormValues({ ...formValues, product: item.key });
                  setErrors({ ...errors, product: undefined });
                }
              }}
              className={classes.mb8}
              options={[
                { key: PRODUCT.SmartDoc, text: PRODUCT.SmartDoc },
                { key: PRODUCT.Transform, text: PRODUCT.Transform },
                { key: PRODUCT.Base_Runtime, text: "Base Runtime (DCIO - Base)" },
                { key: PRODUCT.Runtime_Pro, text: "Runtime Pro (DCIO - Plus)" },
                {
                  key: PRODUCT.Relocate, text: PRODUCT.Relocate
                }
              ]}
            />
            {(formValues.product == PRODUCT.SmartDoc || formValues.product == PRODUCT.Transform) && (
              <CloudFrameDropdown
                placeholder="Select an option"
                label="Environment"
                className={classes.mb8}
                errorMessage={errors.environment}
                required
                defaultSelectedKey={[formValues.environment]}
                onChange={onChangeEnvironmentType}
                options={[
                  { key: ENVIRONMENT.ONPREM, text: ENVIRONMENT.ONPREM },
                  { key: ENVIRONMENT.SAAS, text: ENVIRONMENT.SAAS }
                ]}
              />)}
            {(formValues.product == PRODUCT.Relocate) && (
              <CloudFrameDropdown
                placeholder="Select an option"
                label="Environment"
                className={classes.mb8}
                errorMessage={errors.environment}
                required
                defaultSelectedKey={[formValues.environment]}
                onChange={onChangeEnvironmentType}
                options={[
                  { key: ENVIRONMENT.ONPREM, text: ENVIRONMENT.ONPREM },
                  { key: ENVIRONMENT.ZOS, text: ENVIRONMENT.ZOS }
                ]}
              />)}
            {((formValues.product == PRODUCT.Base_Runtime || formValues.product == PRODUCT.Runtime_Pro) && formValues.licenseType == LICENSE_TYPE.Training) && (
              <CloudFrameDropdown
                placeholder="Select an option"
                label="Environment"
                className={classes.mb8}
                errorMessage={errors.environment}
                required
                defaultSelectedKey={[formValues.environment]}
                onChange={onChangeEnvironmentType}
                options={[
                  { key: ENVIRONMENT.Training, text: ENVIRONMENT.Training },
                ]}
              />)}
            {((formValues.product == PRODUCT.Base_Runtime || formValues.product == PRODUCT.Runtime_Pro) && formValues.licenseType !== LICENSE_TYPE.Training) && (
              <CloudFrameDropdown
                placeholder="Select an option"
                label="Environment"
                className={classes.mb8}
                errorMessage={errors.environment}
                required
                defaultSelectedKey={[formValues.environment]}
                onChange={onChangeEnvironmentType}
                options={[
                  { key: ENVIRONMENT.ONPREM, text: ENVIRONMENT.ONPREM },
                ]}
              />)}
            {formValues.product !== PRODUCT.Runtime_Pro && formValues.product !== PRODUCT.Base_Runtime
              && formValues.product !== PRODUCT.SmartDoc && (
                <CloudFrameTextBox
                  onChange={(_, value = "") => {
                    setFormValues({
                      ...formValues,
                      linesOfCode: parseInt(value),
                      ...(formValues.product === PRODUCT.Transform ? { SmartDocLOC: parseInt(value) } : {}),
                    });
                  }}
                  label= "Lines of code (LOC)"
                  type="number"
                  required
                  
                  value={`${formValues.product === "Relocate"
                    ? Number.MAX_SAFE_INTEGER
                    : formValues.linesOfCode || ""
                    }`}
                  className={classes.mb8}
                  disabled={
                    formValues.product === "Relocate" ||
                    formValues.licenseType == LICENSE_TYPE.Training
                  }
                />
              )}
              {(formValues.product === PRODUCT.Transform || formValues.product === PRODUCT.SmartDoc)
              && (
              <CloudFrameTextBox
                  onChange={(_, value = "") => {
                    setFormValues({
                      ...formValues,
                      SmartDocLOC: parseInt(value),
                    });
                  }}
                  label="SmartDoc LOC"
                  type="number"
                  required
                  value={`${ formValues.SmartDocLOC || "" }`}
                  className={classes.mb8}
                />
                  )}
            {formValues.product !== PRODUCT.Runtime_Pro && formValues.product !== PRODUCT.Base_Runtime
              && (
                <CloudFrameDropdown
                  placeholder="Select an option"
                  label="Lines of code category"
                  children={getLocCategoryHint()}
                  required
                  defaultSelectedKey={[formValues.linesOfCodeCategory]}
                  errorMessage={errors.linesOfCodeCategory}
                  className={classes.mb8}
                  onRenderLabel={onRenderLabel}
                  onChange={(e, item: any) => {
                    setFormValues({ ...formValues, linesOfCodeCategory: item.key });
                    setErrors({ ...errors, linesOfCodeCategory: undefined });
                  }}
                  options={[
                    { key: "LOW", text: "Copybook included in programs" },
                    { key: "HIGH", text: "Copybook Separated from programs" },
                  ]}
                />)}
            {formValues.licenseType !== LICENSE_TYPE.Training && (
              <>
                <CloudFrameDatepicker
                  className={classes.mb8}
                  firstDayOfWeek={DayOfWeek.Monday}
                  value={
                    formValues.platformEffectiveDate
                      ? new Date(formValues.platformEffectiveDate)
                      : undefined
                  }
                  label={" Platform Effective Date"}
                  // minDate={new Date()}
                  isRequired={!formValues.platformEffectiveDate}
                  disabled={formValues.licenseType == LICENSE_TYPE.Training}
                  onSelectDate={(date) => {
                    const newEffectiveDate = getStartingOfDay(date);
                    const currentExpiryDate = formValues.platformExpiryDate;
                    const adjustedExpiryDate =
                      currentExpiryDate &&
                        new Date(currentExpiryDate) <
                        new Date(newEffectiveDate || "")
                        ? getTomorrow(newEffectiveDate)
                        : new Date(currentExpiryDate);

                    setFormValues({
                      ...formValues,
                      platformEffectiveDate: newEffectiveDate,
                      platformExpiryDate:
                        adjustedExpiryDate?.toISOString() || undefined,
                    });
                    setErrors({ ...errors, platformEffectiveDate: undefined });
                  }}
                  placeholder="Select a date..."
                  ariaLabel="Select a date"
                  strings={defaultDatePickerStrings}
                />
                {!((formValues.licenseType === LICENSE_TYPE.Customer || formValues.licenseType === LICENSE_TYPE.Pilot) && formValues.product === PRODUCT.Base_Runtime) && (
                  <CloudFrameDatepicker
                    firstDayOfWeek={DayOfWeek.Monday}
                    label={"Platform Expiry Date"}
                    isRequired={!formValues.platformExpiryDate}
                    disabled={formValues.licenseType == LICENSE_TYPE.Training}
                    minDate={getTomorrow(formValues.platformEffectiveDate)}
                    value={
                      formValues.platformExpiryDate
                        ? new Date(formValues.platformExpiryDate)
                        : getTomorrow(formValues.platformEffectiveDate)
                    }
                    onSelectDate={(date) => {
                      setFormValues({
                        ...formValues,
                        platformExpiryDate: date,
                      });
                      setErrors({ ...errors, expiryDate: undefined });
                    }}
                    placeholder="Select a date..."
                    ariaLabel="Select a date"
                    strings={defaultDatePickerStrings}
                  />
                )}
              </>
            )}
            <CloudFrameTextBox
              onChange={(_, value) => {
                setFormValues({ ...formValues, serviceDeskID: value });
              }}
              required
              label="Service Desk Id"
              className={classes.mb8}
              value={formValues.serviceDeskID}
              disabled={!!data}
            />
            <CloudFrameDropdown
              placeholder="Select an option"
              className={classes.mb8}
              required

              defaultSelectedKey={[formValues.supportLevel]}
              errorMessage={errors.supportLevel}
              onChange={(e, item: any) => {
                setFormValues({ ...formValues, supportLevel: item.key });
                setErrors({ ...errors, supportLevel: undefined });
              }}
              label="Support Level"
              options={[
                { key: "PREMIUM", text: "Premium" },
                { key: "PREMIUM_PLUS", text: "Premium plus" },
              ]}
            />
            <CloudFrameDropdown
              placeholder="Select an option"
              className={classes.mb8}
              errorMessage={errors.productTrainingProvided}
              required
              defaultSelectedKey={[
                formValues.productTrainingProvided === undefined
                  ? ""
                  : formValues.productTrainingProvided
                    ? "yes"
                    : "no",
              ]}
              onChange={(e, item: any) => {
                setFormValues({
                  ...formValues,
                  productTrainingProvided: item.key === "yes",
                });
                setErrors({ ...errors, productTrainingProvided: undefined });
              }}
              label="Product training provided?"
              options={[
                { key: "yes", text: "Yes" },
                { key: "no", text: "No" },
              ]}
            />
            <CloudFrameTextBox
              label="Consulting hours in agreement"
              className={classes.mb8}
              required
              errorMessage={errors.consultingHours}
              value={`${formValues.consultingHours || "0"}`}
              type="number"
              onChange={(_, value = "") => {
                setFormValues({
                  ...formValues,
                  consultingHours: parseInt(value),
                });
              }}
            />
            <CloudFrameDropdown
              placeholder="Select an option"
              label="Enable CDN for license validation"
              required
              errorMessage={errors.enableCDN}
              defaultSelectedKey={
                [formValues.enableCDN]
              }
              onChange={(e, item: any) => {
                setFormValues({ ...formValues, enableCDN: item.key });
                setErrors({ ...errors, enableCDN: undefined });
              }}
              className={classes.mb8}
              options={[
                { key: "true", text: "Yes" },
                { key: "false", text: "No" },
              ]}
              disabled={
                formValues.product === "Relocate" ||
                formValues.licenseType == LICENSE_TYPE.Training
              }
            />
            <CloudFrameTextBox
              label="Jira Ticket Link (URL)"
              className={classes.mb8}
              value={formValues.jiraTicket}
              disabled={!!data}
              errorMessage={errors.jiraTicket}
              onChange={(_, value) => {
                setFormValues({ ...formValues, jiraTicket: value });
                const errorMessage = !jiraTicketPattern.test(value || "")
                  ? "Please provide valid Jira ticket"
                  : undefined;
                setErrors({ ...errors, jiraTicket: errorMessage });
              }}
              required
            />

            <div style={{ marginTop: "1.5rem" }}>
              <CloudFrameCheckbox
                boxSide="end"
                label="Offline license"
                styles={{
                  label: {
                    fontWeight: 600,
                  },
                }}
                name="isOfflineLicense"
                checked={formValues.isOfflineLicense}
                onChange={(_: any, checked: any) => {
                  setFormValues({
                    ...formValues,
                    isOfflineLicense: !!checked,
                  });
                }}
                disabled={!!data}
              />
            </div>
            <div className={classes.btnContainer}>
              <CloudFramePrimaryButton
                text={data ? "Renew" : "Create"}
                type="submit"
                disabled={
                  formSubmitting ||
                  (Number.isInteger(data?.id) &&
                    data?.status !== LICENSE_STATUS.EXPIRED)
                }
                className={`${classes.mt16} ${classes.ml2}`}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LicenseForm;
