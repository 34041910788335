import React, { useEffect, useState } from "react";
import { Modal, Text } from "@fluentui/react";
import { IModalStyles, getTheme } from "@fluentui/react";
import { useStyles } from "./activationkey";
import { CloudFramePrimaryButton } from "@cloudframe/button";
import { GET_ALL_ACTIVATION_KEYS } from "../../graphql-query/activation-key.query";
import { useMutation, useQuery } from "@apollo/client";
import { GET_LICENSE_INFO } from "../../graphql-query/license.query";
import { platform } from "os";
import { CREATE_ACTIVATION_KEY } from "../../graphql-mutations/create.activation.key";
import { compressTheContent, decompress } from "../../utils/compress.utils";
import { formatDateToMMDDYYYY } from "../../utils/date.utils";
import { useNavigate } from "react-router-dom";

const theme = getTheme();

interface LicenseInfo {
    LicenseId: string;
    LicensedFor: string;
    LicensedProduct: string;
    LicenseType: string;
    Environment: string;
    PlatformEffectiveDate: string;
    PlatformExpiryDate: string;
    SmartDocLOC: number;
    LinesOfCodeCategory: string;
    LinesOfCode?: number;  // Optional field
}

const GenerateActivationKey: React.FC = () => {
    const classes = useStyles();
    const [fingerprint, setFingerprint] = useState<string>("");
    const [existingActivationKey, setExistingActivationKey] = useState<string | null>(null);
    const [activationKey, setActivationKey] = useState<string | null>(null);
    const [isKeyModalOpen, setIsKeyModalOpen] = useState<boolean>(false);
    const [copied, setCopied] = useState(false);
    const [decompressedDetails, setDecompressedDetails] = useState<{
        hardwareFingerPrint?: string;
        usageFingerPrint?: {
            linesUsed: number;
            smartDocsLinesUsed: number;
            totalLimit: number;
            totalSmartDocLimit:number;
        };
        licenseId?: string;
    } | null>(null);
    const [isDuplicateKeyModalOpen, setIsDuplicateKeyModalOpen] = useState<boolean>(false); 
    const [createActivationKey] = useMutation(CREATE_ACTIVATION_KEY);
    const [existingKeys, setExistingKeys] = useState<{fingerprint: string; licenseId: string; expiryDate: Date}[]>([]);
    const [isKeyGenerated, setIsKeyGenerated] = useState<boolean>(false);
    const [isLicenseExpiryModalopen, setLicenseExpiryModal] = useState<boolean>(false);
    const [ActivationKeyInvalid, setActivationInvalidModel] = useState<boolean>(false);

    const { data: licenseData } = useQuery(GET_LICENSE_INFO, {
        skip: !decompressedDetails?.licenseId,
        variables: { licenseId: decompressedDetails?.licenseId?.toString() },
    });

    const { data: keyData, loading, error } = useQuery(GET_ALL_ACTIVATION_KEYS);
    const navigate = useNavigate();

    useEffect(() => {
        if (!loading && !error && keyData && keyData.getAllActivationKeys) {
            setExistingKeys(
                keyData.getAllActivationKeys.map((key: { fingerprint: string , expiryDate: Date}) => ({
                    fingerprint: key.fingerprint,
                    expiryDate : key.expiryDate
                }))
            );
        }
    }, [keyData, loading, error]);
    
    

    const handleCopy = () => {
        if (activationKey) {
            navigator.clipboard.writeText(activationKey);
            setCopied(true);
            setTimeout(() => setCopied(false), 1500);
        }
    };

    const modalStyles: Partial<IModalStyles> = {
        main: {
            padding: "20px",
            borderRadius: "8px",
            backgroundColor: theme.palette.white,
            boxShadow: theme.effects.elevation8,
        },
    };

    const handleDecompression = (fingerprint: string) => {
        try {
            const decompressedData = decompress(fingerprint);
            if (decompressedData) {
                setDecompressedDetails(JSON.parse(decompressedData));
            } else {
                console.error("Decompression failed.");
                setDecompressedDetails(null);
            }
        } catch (error) {
            console.error("Decompression failed:", error);
            alert("Invalid Finger Print")
            setDecompressedDetails(null);
        }
    };

    const calculateActivationExpiryDate = () => {
        const today = new Date();
        let businessDays = 0;
    
        while (businessDays < 5) {
            today.setDate(today.getDate() + 1);
            const dayOfWeek = today.getDay();
            if (dayOfWeek !== 0 && dayOfWeek !== 6) { // 0 = Sunday, 6 = Saturday
                businessDays++;
            }
        }
        return formatDateToMMDDYYYY(today); // Assuming formatDateToMMDDYYYY formats the date as needed
    };

    const isActivationKeyValid = (expiryDate) => {
        const today = new Date();
        return today <= new Date(expiryDate); // returns true if the expiry date is in the future, false if expired
    };

    const handleGenerateKeyClick = async () => {

        if (isKeyGenerated) {
            setIsDuplicateKeyModalOpen(true);
            return;
        }
        if (!decompressedDetails) {
            alert("Please decompress the fingerprint before generating the key.");
            return;
        }
    
        if (!decompressedDetails.licenseId || decompressedDetails.licenseId === "N/A") {
            alert("License ID is missing.");
            return;
        }
    
        // Check if an activation key for the same fingerprint and license ID already exists
        const existingKey = existingKeys.find(
            (key) => key.fingerprint === fingerprint 
        );

        const Check = existingKey?.expiryDate;

        if (existingKey) {
            if(isActivationKeyValid(Check)){
            setIsDuplicateKeyModalOpen(true);
            return; 
          }
            if(!(isActivationKeyValid(Check))){
            setActivationInvalidModel(true);
            return;
            }
        }
    
        if (!licenseData) {
            alert("No License Data Found")
            return;
        }
    
        const licenseInfo = licenseData.getLicenseInfo || {};
        const platformExpiryDate = new Date(licenseInfo.platformExpiryDate);
        const today = new Date();
             if (platformExpiryDate < today) {
                 setLicenseExpiryModal(true);
                 return;
             }

        const activationExpiryDate = calculateActivationExpiryDate();

        // Define the licenseInfo object with an optional LinesOfCode field
        const licenseInfoDetails: LicenseInfo = {
            LicenseId: licenseInfo.licenseId || "N/A",
            LicensedFor: licenseInfo.customerName || "N/A",
            LicensedProduct: licenseInfo.product || "N/A",
            LicenseType: licenseInfo.licenseType || "N/A",
            Environment: licenseInfo.environment || "N/A",
            PlatformEffectiveDate: formatDateToMMDDYYYY(licenseInfo.platformEffectiveDate) || "N/A",
            PlatformExpiryDate: formatDateToMMDDYYYY(licenseInfo.platformExpiryDate) || "N/A",
            SmartDocLOC: licenseInfo.SmartDocLOC || 0,
            LinesOfCodeCategory: licenseInfo.linesOfCodeCategory || "00000",
        };
        console.log(licenseInfo);
        // Conditionally add LinesOfCode only if LicenseType is not "SmartDoc"
        if (licenseInfo.product !== "SmartDoc") {
            licenseInfoDetails.LinesOfCode = licenseInfo.linesOfCode;
        }

        const combinedDetails = {
            hardwareFingerPrint: decompressedDetails.hardwareFingerPrint || "N/A",
            usageFingerPrint: {
                linesUsed: decompressedDetails.usageFingerPrint?.linesUsed ?? 0,
                smartDocsLinesUsed: decompressedDetails.usageFingerPrint?.smartDocsLinesUsed ?? 0,
                totalLimit: decompressedDetails.usageFingerPrint?.totalLimit ?? 0,
                totalSmartDocLimit: decompressedDetails.usageFingerPrint?.totalSmartDocLimit ?? 0,
            },
            licenseId: decompressedDetails.licenseId || "N/A",
            licenseInfo: licenseInfoDetails,
            activationExpiryDate: activationExpiryDate
        };

        console.log("Combined Details:", combinedDetails);
    
        const compressedData = compressTheContent(JSON.stringify(combinedDetails));
        if (compressedData) {
            setActivationKey(compressedData);
            setIsKeyModalOpen(true);
            setIsKeyGenerated(true); 
            await createActivationKey({
                variables: {
                    data: {
                        licenseId: licenseInfo.licenseId || "N/A",
                        activationKey: compressedData,
                        customerName: licenseInfo.customerName || "N/A",
                        fingerprint,
                        expiryDate: activationExpiryDate
                    },
                },
            });
        } else {
            alert("Failed to generate activation key.");
        }
    };

    const handleGenerateNewKeyClick = async() =>{

        if (!decompressedDetails) {
            alert("Please decompress the fingerprint before generating the key.");
            return;
        }
    
        if (!decompressedDetails.licenseId || decompressedDetails.licenseId === "N/A") {
            alert("License ID is missing.");
            return;
        }

        const activationExpiryDate = calculateActivationExpiryDate();
        const licenseInfo = licenseData.getLicenseInfo || {};

        // Define the licenseInfo object with an optional LinesOfCode field
        const licenseInfoDetails: LicenseInfo = {
            LicenseId: licenseInfo.licenseId || "N/A",
            LicensedFor: licenseInfo.customerName || "N/A",
            LicensedProduct: licenseInfo.product || "N/A",
            LicenseType: licenseInfo.licenseType || "N/A",
            Environment: licenseInfo.environment || "N/A",
            PlatformEffectiveDate: formatDateToMMDDYYYY(licenseInfo.platformEffectiveDate) || "N/A",
            PlatformExpiryDate: formatDateToMMDDYYYY(licenseInfo.platformExpiryDate) || "N/A",
            SmartDocLOC: licenseInfo.SmartDocLOC || 0,
            LinesOfCodeCategory: licenseInfo.linesOfCodeCategory || "00000",
        };
        console.log(licenseInfoDetails);

        // Conditionally add LinesOfCode only if LicenseType is not "SmartDoc"
        if (licenseInfo.product !== "SmartDoc") {
            licenseInfoDetails.LinesOfCode = licenseInfo.linesOfCode;
        }

        const combinedDetails = {
            hardwareFingerPrint: decompressedDetails.hardwareFingerPrint || "N/A",
            usageFingerPrint: {
                linesUsed: decompressedDetails.usageFingerPrint?.linesUsed ?? 0,
                smartDocsLinesUsed: decompressedDetails.usageFingerPrint?.smartDocsLinesUsed ?? 0,
                totalLimit: decompressedDetails.usageFingerPrint?.totalLimit ?? 0,
                totalSmartDocLimit: decompressedDetails.usageFingerPrint?.totalSmartDocLimit ?? 0,
            },
            licenseId: decompressedDetails.licenseId || "N/A",
            licenseInfo: licenseInfoDetails,
            activationExpiryDate: activationExpiryDate
        };

        console.log("Combined Details:", combinedDetails);
        const compressedData = compressTheContent(JSON.stringify(combinedDetails));
        if (compressedData) {
            setActivationKey(compressedData);
            setIsKeyModalOpen(true);
            setIsKeyGenerated(true); 
            await createActivationKey({
                variables: {
                    data: {
                        licenseId: licenseInfo.licenseId || "N/A",
                        activationKey: compressedData,
                        customerName: licenseInfo.customerName || "N/A",
                        fingerprint,
                        expiryDate: activationExpiryDate
                    },
                },
            });
        } else {
            alert("Failed to generate activation key.");
        }
    }
    

    const closeKeyModal = () => {
        setIsKeyModalOpen(false);
        navigate("/activationkeylist");
    }
    const closeDuplicateKeyModal = () => setIsDuplicateKeyModalOpen(false);

    const closeLicenseExpiryModal = () => {
        setLicenseExpiryModal(false);
        window.location.reload();
    };
    const closeIvalidModal = () => setActivationInvalidModel(false);

    return (
        <div
            style={{
                backgroundColor: "white",
                borderRadius: "15px",
                padding: "20px",
                paddingBottom: "40px",
                marginTop: "15px",
            }}
            className={classes.root}
        >
            <br />
            <div style={{ marginLeft: "20px" }}>
                <h3 className={classes.mt16}>Generate Activation Key</h3>

                <div>
                    <br />
                    <label>Enter Hardware Fingerprint:</label>
                    <br />
                    <textarea
                        id="fingerprint"
                        value={fingerprint}
                        onChange={(e) => setFingerprint(e.target.value)}
                        style={{ width: "40%", padding: "8px", marginBottom: "20px", borderRadius: "4px", marginTop: "20px", height: "100px" }}
                    />
                </div>

                <CloudFramePrimaryButton
                    onClick={() => handleDecompression(fingerprint)}  // Updated here
                    style={{
                        backgroundColor: theme.palette.themePrimary,
                        color: theme.palette.white,
                        padding: "5px 10px",
                        marginRight: "10px",
                        borderRadius: "4px",
                        paddingBottom: "10px",
                    }}
                    text="Decompress Fingerprint"
                />
                <CloudFramePrimaryButton
                     onClick={handleGenerateKeyClick}
                    style={{
                        backgroundColor: theme.palette.green,
                        color: theme.palette.white,
                        padding: "5px 10px",
                        borderRadius: "4px",
                        paddingBottom: "10px",
                    }}
                    text="Generate Activation Key"
                />

                <div
                    style={{
                        marginTop: "20px",
                        padding: "10px",
                        backgroundColor: "white",
                        borderRadius: "8px",
                        minHeight: "100px",
                    }}
                >
                     {decompressedDetails && (
                        <>
                            <Text variant="large"><b>License Details</b></Text>
                            <p>Hardware Fingerprint: {decompressedDetails.hardwareFingerPrint}</p>
                            <p>Lines Used: {decompressedDetails.usageFingerPrint?.linesUsed ?? 0}</p>
                            <p>SmartDocs Lines Used: {decompressedDetails.usageFingerPrint?.smartDocsLinesUsed ?? 0}</p>
                            <p>Total Limit: {decompressedDetails.usageFingerPrint?.totalLimit ?? 0}</p>
                            <p>License ID: {decompressedDetails.licenseId}</p>
                        </>
                    )}
                </div>
            </div>

            <Modal
                isOpen={isKeyModalOpen}
                onDismiss={closeKeyModal}
                isBlocking={false}
                styles={modalStyles}
            >
                <div className={classes.modalContent}>
                    {activationKey && (
                        <>
                            <Text variant="large">Activation Key Generated</Text>
                            {/* Display the activation key, splitting it into lines */}
                            <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                                <p>{activationKey}</p>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", gap: "10px", fontSize: "20px" }}>
                            <CloudFramePrimaryButton
                                 onClick={handleCopy}
                                style={{
                                     backgroundColor: theme.palette.themePrimary,
                                     color: theme.palette.white,
                                     padding: "5px 10px",
                                     marginTop: "10px",
                                    }}
                                    text="Copy Activation Key" // Styled button for copying
                                    />
                                {copied && (
                                    <span style={{ fontSize: "16px" }}>Copied!</span>
                                )}
                            </div>
                            <CloudFramePrimaryButton
                                onClick={closeKeyModal}
                                style={{
                                    backgroundColor: theme.palette.red,
                                    color: theme.palette.white,
                                    padding: "5px 10px",
                                    marginTop: "10px",
                                }}
                                text="Close"
                            />
                        </>
                    )}
                </div>
            </Modal>
            <Modal
                isOpen={isDuplicateKeyModalOpen}
                onDismiss={closeDuplicateKeyModal}
                isBlocking={false}
                styles={modalStyles}
            >
                <div style={{ padding: "20px" }}>
                    <Text variant="large">Warning</Text>
                    <p>An activation key with the same fingerprint already exists.</p>
                    <br />
                    <p>Do you want to generate a new activation key ...?</p>
                    <CloudFramePrimaryButton
                        onClick={handleGenerateNewKeyClick}
                        style={{
                            backgroundColor: theme.palette.blue,
                            color: theme.palette.white,
                            padding: "5px 10px",
                            borderRadius: "4px",
                            paddingBottom: "10px",
                            marginRight:"10px"
                        }}
                        text="Yes"
                    />
                    <CloudFramePrimaryButton
                        onClick={closeDuplicateKeyModal}
                        style={{
                            backgroundColor: theme.palette.red,
                            color: theme.palette.white,
                            padding: "5px 10px",
                            borderRadius: "4px",
                            paddingBottom: "10px",
                        }}
                        text="Close"
                    />
                </div>
            </Modal>
            <Modal
                isOpen={isLicenseExpiryModalopen}
                onDismiss={closeLicenseExpiryModal}
                isBlocking={false}
                styles={modalStyles}
            >
                <div style={{ padding: "20px" }}>
                    <Text variant="large">Warning</Text>
                    <p>License is invalid.</p>
                    <CloudFramePrimaryButton
                        onClick={closeLicenseExpiryModal}
                        style={{
                            backgroundColor: theme.palette.red,
                            color: theme.palette.white,
                            padding: "5px 10px",
                            borderRadius: "4px",
                            paddingBottom: "10px",
                        }}
                        text="Close"
                    />
                </div>
            </Modal>
            <Modal
                isOpen={ActivationKeyInvalid}
                onDismiss={closeIvalidModal}
                isBlocking={false}
                styles={modalStyles}
            >
                <div style={{ padding: "20px" }}>
                    <Text variant="large">Warning</Text>
                    <p>Activation Key is Invalid.</p>
                    <CloudFramePrimaryButton
                        onClick={handleGenerateNewKeyClick}
                        style={{
                            backgroundColor: theme.palette.green,
                            color: theme.palette.white,
                            padding: "5px 10px",
                            borderRadius: "4px",
                            paddingBottom: "10px",
                            marginRight: "10px"
                        }}
                        text="Generate New Activation Key"
                    />
                    <CloudFramePrimaryButton
                        onClick={closeIvalidModal}
                        style={{
                            backgroundColor: theme.palette.red,
                            color: theme.palette.white,
                            padding: "5px 10px",
                            borderRadius: "4px",
                            paddingBottom: "10px",
                        }}
                        text="Close"
                    />
                </div>
            </Modal>
        </div>
    );
};

export default GenerateActivationKey;
