import { gql } from "@apollo/client";

export const QUERY_GET_LICENSES = gql`
  query GetAllLicenses {
    getAllLicenses {
      id
      customerName
      email
      licenseType
      product
      environment
      linesOfCode
      linesOfCodeCategory
      platformEffectiveDate
      platformExpiryDate
      supportLevel
      productTrainingProvided
      consultingHours
      isOfflineLicense
      status
      enableCDN
      licenseId
      jiraTicket
      denialReason
      approvedBy
      allowedProgram
      allowedJcl
      SmartDocLOC
      serviceDeskID
    }
  }
`;

export const QUERY_GET_LICENSE_AUDIT_LOG = gql`
  query GetLicenseAuditTrail {
    getLicenseAuditLogs {
      id
      entity
      eventType
      eventDate
      eventCreator
      eventId
      newData
      oldData
    }
  }
`;

export const QUERY_GET_LICENSE_SUMMARY = gql`
  query GetLicenseSummary {
    getLicenseSummary {
      licenseId
      customerName
      email
      platformEffectiveDate
      platformExpiryDate
      licenseType
      product
      linesOfCode
      linesOfCodeUsed
      environment
      productTrainingProvided
      enableCDN
      linesOfCodeCategory
      supportLevel
      allowedProgram
      allowedJcl
      consultingHours
      jiraTicket
      SmartDocLOC
      serviceDeskID
    }
  }
`;

export const QUERY_GET_LICENSE = gql`
  query GetLicense($id: Float!) {
    getLicense(id: $id) {
      id
      customerName
      email
      licenseType
      product
      linesOfCode
      linesOfCodeCategory
      platformEffectiveDate
      platformExpiryDate
      supportLevel
      productTrainingProvided
      consultingHours
      isOfflineLicense
      jiraTicket
      SmartDocLOC
      serviceDeskID
    }
  }
`;

export const GET_LICENSE_INFO = gql`
  query GetLicenseInfo($licenseId: String!) {
    getLicenseInfo(licenseId: $licenseId) {
      licenseId
      customerName
      platformEffectiveDate
      platformExpiryDate
      licenseType
      product
      linesOfCode
      linesOfCodeCategory
      allowedProgram
      allowedJcl
      jiraTicket
      SmartDocLOC
      environment
    }
  }
`;


export const QUERY_GET_LICENSE_TEXT = gql`
  query GetLicense($id: Float!) {
    getLicense(id: $id) {
      id
      licenseText
    }
  }
`;

export const QUERY_GET_DCIO_LICENSE_TEXT = gql`
  query GetLicense($id: Float!) {
    getLicense(id: $id) {
      id
      dcioLicenseText
    }
  }
`;

export const QUERY_GET_CDN_STATISTICS = gql`
  query GetLicenseHistoryByLicenseId($licenseId: String!) {
    getLicenseHistoryByLicenseId(licenseId: $licenseId) {
      licenseId
      date
      macId
      linesUsed
      programType
      programHash
    }
  }
`;

export const QUERY_GET_LICENSE_WITH_HISTORY = gql`
  query GetLicense($licenseId: Float!) {
    getLicense(id: $licenseId) {
      id
      email
      customerName
      product
      linesOfCode
      effectiveDate
      expiryDate
      supportLevel
      consultingHours
      status
      isOfflineLicense
      jiraTicket
      serviceDeskID
      licenseHistory {
        id
        date
        deviceId
        linesUsed
      }
    }
  }
`;

export const QUERY_GET_LICENSE_STATUS = gql`
  query Query($expiryDays: Float!, $product: Product!) {
    licenseStatus(expiryDays: $expiryDays, product: $product)
  }
`;

export const QUERY_SEND_LICENSE_EXPIRY_EMAIL = gql`
  query Query($licenseId: Float!) {
    sendLicenseExpiryMail(licenseId: $licenseId)
  }
`;

export const QUERY_GET_LOGS = gql`
  query GetLogContent {
    getLogContent {
      status
      message
    }
  }
`;
