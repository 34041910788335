import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import {
    Modal,
    Stack,
    Text,
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    SelectionMode,
    Selection,
} from "@fluentui/react";
import { useStyles } from "./activationkeyupdate";
import { CloudFrameDataTable } from "@cloudframe/data-table";
import { CloudFramePrimaryButton } from "@cloudframe/button";
import { IActionButtonTitle } from "../action-buttons/action-button.intefaces";
import ActionButtons from "../action-buttons/action-buttons";
import { GET_ALL_ACTIVATION_KEYS } from "../../graphql-query/activation-key.query";
import { toISOShortDateString } from "../../utils/date.utils";

interface ActivationKey {
    activationKey: string;
    fingerprint: string;
    expiryDate: string;
}

interface Customer {
    id: number;
    name: string;
    activationKeys: ActivationKey[];
}


const UpdateActivationKeyStatusPage: React.FC = () => {
    const classes = useStyles();
    const { data } = useQuery(GET_ALL_ACTIVATION_KEYS); // Fetch data with the useQuery hook
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false);
    const [selectedActivationKey, setSelectedActivationKey] = useState<ActivationKey | null>(null);
    
    // State to keep track of the previously selected activation key
    const [previousActivationKey, setPreviousActivationKey] = useState<ActivationKey | null>(null);

    // Create a selection object
    const selection = new Selection({
        onSelectionChanged: () => {
            const selectedItems = selection.getSelection();
            if (selectedItems.length > 0) {
                const activationKey = selectedItems[0] as ActivationKey;
                // Check if the selected activation key is different from the previous one
                if (activationKey !== previousActivationKey) {
                    setPreviousActivationKey(activationKey); // Update previous key
                    openDetailModal(activationKey); // Open modal with the selected activation key
                }
            }
        },
    });

    useEffect(() => {
        if (data && data.getAllActivationKeys) {
            const customersMap: { [key: string]: Customer } = {};
            let customerId = 1; // Initialize a unique ID counter

            data.getAllActivationKeys.forEach((key: any) => {
                if (!customersMap[key.customerName]) {
                    customersMap[key.customerName] = {
                        id: customerId++, // Use the counter for unique ID
                        name: key.customerName,
                        activationKeys: [],
                    };
                }
                customersMap[key.customerName].activationKeys.push({
                    activationKey: key.activationKey,
                    fingerprint: key.fingerprint,
                    expiryDate: key.expiryDate,
                });
            });
            setCustomers(Object.values(customersMap));
        }
    }, [data]);

    const openModalForCustomer = (customer: Customer) => {
        setSelectedCustomer(customer);
        selection.setItems([]); // Reset selection when opening the modal for a customer
        setIsModalOpen(true);
        setPreviousActivationKey(null); // Reset previous activation key
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedCustomer(null);
        setSelectedActivationKey(null); // Reset selected activation key
        selection.setItems([]); // Deselect any selected item
        setPreviousActivationKey(null); // Reset previous activation key
    };

    const openDetailModal = (activationKey: ActivationKey) => {
        setSelectedActivationKey(activationKey);
        setIsDetailModalOpen(true);
    };

    const closeDetailModal = () => {
        setIsDetailModalOpen(false);
        setSelectedActivationKey(null);
        selection.setItems([]); // Deselect the activation key when closing the detail modal
        setPreviousActivationKey(null); // Reset previous activation key
    };

    const getActions = (props: any): IActionButtonTitle => {
        const actionButtonTitles: IActionButtonTitle = {
            viewTitle: "View Details",
        };
        return actionButtonTitles;
    };

    const customerColumns: IColumn[] = [
        {
            key: "name",
            name: "Customer Name",
            fieldName: "name",
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
            onRender: (customer: Customer) => (
                <span style={{ fontSize: "16px" }}>{customer.name}</span>
            ),
        },
        {
            key: "action",
            name: "Action",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender: (props) => (
                <ActionButtons
                    onView={() => openModalForCustomer(props)}
                    row={props}
                    buttonTitles={getActions(props)}
                />
            ),
            isPadded: false,
        },
    ];

    const activationKeyColumns: IColumn[] = [
        { key: "activationKey", name: "Activation Key", fieldName: "activationKey", minWidth: 100, maxWidth: 120, isResizable: false },
        { key: "fingerprint", name: "Fingerprint", fieldName: "fingerprint", minWidth: 100, maxWidth: 120, isResizable: false },
        { 
            key: "expiryDate", 
            name: "Expiry Date", 
            fieldName: "expiryDate", 
            minWidth: 100, 
            maxWidth: 120, 
            isResizable: false,
            onRender: (item: { expiryDate: Date }) => toISOShortDateString(item.expiryDate), // Format expiryDate here
        },
    ];
    

    return (
        <div style={{ padding: 25, backgroundColor: "white", borderRadius: "15px", marginTop: "15px" }}>
            <h3>Customer List</h3>
            <CloudFrameDataTable
                items={customers}
                selectionMode={SelectionMode.none}
                columns={customerColumns}
                className={classes.tableBody}
                styles={{
                    root: {
                        rowGap: "10px",
                    },
                }}
            />

            {/* Modal for Customer Activation Keys */}
            <Modal
                isOpen={isModalOpen}
                onDismiss={closeModal}
                isBlocking={false}
                styles={{
                    main: {
                        width: "100%",
                        maxWidth: "600px",
                        padding: "20px",
                        borderRadius: "8px",
                        backgroundColor: "#fff",
                    },
                }}
            >
                <Stack tokens={{ childrenGap: 15 }}>
                    <Text variant="large">Activation Keys for {selectedCustomer?.name}</Text>
                    {selectedCustomer && (
                        <DetailsList
                            items={selectedCustomer.activationKeys}
                            columns={activationKeyColumns}
                            layoutMode={DetailsListLayoutMode.justified}
                            selection={selection} // Use the selection object
                            selectionMode={SelectionMode.single} // Allow single selection
                            styles={{
                                root: {
                                    fontSize: "16px",
                                },
                            }}
                        />
                    )}
                </Stack>
                <br />
                <Stack horizontal horizontalAlign="start">
                    <CloudFramePrimaryButton
                        text="Close"
                        onClick={closeModal}
                        styles={{
                            root: {
                                minWidth: "80px",
                                height: "32px",
                            },
                        }}
                    />
                </Stack>
            </Modal>

            {/* Modal for Activation Key Details */}
            <Modal
                isOpen={isDetailModalOpen}
                onDismiss={closeDetailModal}
                isBlocking={false}
                styles={{
                    main: {
                        width: "600px",
                        padding: "20px",
                        borderRadius: "8px",
                        backgroundColor: "#fff",
                    },
                }}
            >
                <Stack tokens={{ childrenGap: 15 }}>
                    <Text variant="large">Activation Key Details</Text>
                    {selectedActivationKey && (
                        <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                            <Text><b>Activation Key:</b><br />{selectedActivationKey.activationKey}</Text>
                            <br />
                            <br />
                            <Text><b>Fingerprint:</b><br /> {selectedActivationKey.fingerprint}</Text>
                            <br />
                            <br />
                            <Text><b>Expiry Date:</b> {toISOShortDateString(selectedActivationKey.expiryDate)}</Text>
                    </div>
                    )}
                </Stack>
                <br />
                <Stack horizontal horizontalAlign="start">
                    <CloudFramePrimaryButton
                        text="Close"
                        onClick={closeDetailModal}
                        styles={{
                            root: {
                                minWidth: "80px",
                                height: "32px",
                            },
                        }}
                    />
                </Stack>
            </Modal>
        </div>
    );
};

export default UpdateActivationKeyStatusPage;
