import pako from 'pako';

export const decompress = (compressedData) => {
    try {
        const binaryString = atob(compressedData);
        const byteArray = new Uint8Array(binaryString.length);

        for (let i = 0; i < binaryString.length; i++) {
            byteArray[i] = binaryString.charCodeAt(i);
        }

        const decompressed = pako.inflate(byteArray, { to: 'string' });
        const original = getInverseDecrypt(decompressed);
        return original;
    } catch (e) {
        console.error("Unable to decompress the data:", e);
        return null;
    }
};

const getInverseDecrypt = (data) => {
    const length = (data.charCodeAt(0) << 8) | data.charCodeAt(1);
    const encryptedData = data.substring(2, 2 + length);
    const reversed = encryptedData.split('').reverse().join('');
    let original = '';
    const OFFSET = 4;

    for (let i = 0; i < reversed.length; i++) {
        original += String.fromCharCode(reversed.charCodeAt(i) - OFFSET);
    }

    return atob(original);
};

export const compressTheContent = (content) => {
    try {
        const encryptedData = getInverseEncrypt(content);
        const compressed = pako.deflate(encryptedData, { to: 'uint8array' }); // Compress as uint8array
        const base64Compressed = btoa(String.fromCharCode(...compressed)); // Convert to base64 string
        return base64Compressed;
    } catch (e) {
        console.error("Unable to compress the data:", e);
        return null;
    }
};

// Function to perform inverse encryption similar to Java's `getInverseEncrypt`
const getInverseEncrypt = (line) => {
    // Step 1: Base64 encode the input line
    const b64encoded = btoa(line);
    
    // Step 2: Reverse the Base64 string
    const reversed = b64encoded.split('').reverse().join('');
    
    // Step 3: Apply offset transformation
    const OFFSET = 4;
    let tmp = '';
    for (let i = 0; i < reversed.length; i++) {
        tmp += String.fromCharCode(reversed.charCodeAt(i) + OFFSET);
    }

    // Step 4: Prepend the length as two bytes (Java's putShort equivalent)
    const length = tmp.length;
    const data = new Uint8Array(tmp.length + 2); // Create a byte array with space for length prefix

    // Store the length as two bytes
    data[0] = (length >> 8) & 0xFF;
    data[1] = length & 0xFF;

    // Append the transformed string data
    for (let i = 0; i < tmp.length; i++) {
        data[i + 2] = tmp.charCodeAt(i);
    }

    return data;
};
